export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const EDIT_CHANGE_PASSWORD = "EDIT_CHANGE_PASSWORD";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const GET_LOGIN_DETAILS = "GET_LOGIN_DETAILS";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const GET_REGISTER_DETAILS = "GET_REGISTER_DETAILS";

export const DISPLAY_FORGOT_PASSWORD = "DISPLAY_FORGOT_PASSWORD";
export const DISPLAY_LOGIN = "DISPLAY_LOGIN";
export const DISPLAY_SIGNUP = "DISPLAY_SIGNUP";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const GET_FORGOT_PASSWORD_DETAILS = "GET_FORGOT_PASSWORD_DETAILS";

export const FETCH_SUBSCRIPTION_START = "FETCH_SUBSCRIPTION_START";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILURE = "FETCH_SUBSCRIPTION_FAILURE";

export const FETCH_MY_SUBSCRIPTION_START = "FETCH_MY_SUBSCRIPTION_START";
export const FETCH_MY_SUBSCRIPTION_SUCCESS = "FETCH_MY_SUBSCRIPTION_SUCCESS";
export const FETCH_MY_SUBSCRIPTION_FAILURE = "FETCH_MY_SUBSCRIPTION_FAILURE";

export const FETCH_SINGLE_SUBSCRIPTION_START =
  "FETCH_SINGLE_SUBSCRIPTION_START";
export const FETCH_SINGLE_SUBSCRIPTION_SUCCESS =
  "FETCH_SINGLE_SUBSCRIPTION_SUCCESS";
export const FETCH_SINGLE_SUBSCRIPTION_FAILURE =
  "FETCH_SINGLE_SUBSCRIPTION_FAILURE";

export const SUBSCRIPTION_PAYMENT_START = "SUBSCRIPTION_PAYMENT_START";
export const SUBSCRIPTION_PAYMENT_SUCCESS = "SUBSCRIPTION_PAYMENT_SUCCESS";
export const SUBSCRIPTION_PAYMENT_FAILURE = "SUBSCRIPTION_PAYMENT_FAILURE";

export const FETCH_CARD_DETAILS_START = "FETCH_CARD_DETAILS_START";
export const FETCH_CARD_DETAILS_SUCCESS = "FETCH_CARD_DETAILS_SUCCESS";
export const FETCH_CARD_DETAILS_FAILURE = "FETCH_CARD_DETAILS_FAILURE";

export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const SELECT_DEFAULT_CARD_START = "SELECT_DEFAULT_CARD_START";
export const SELECT_DEFAULT_CARD_SUCCESS = "SELECT_DEFAULT_CARD_SUCCESS";
export const SELECT_DEFAULT_CARD_FAILURE = "SELECT_DEFAULT_CARD_FAILURE";

export const SEND_CONNECTION_ID_START = "SEND_CONNECTION_ID_START";
export const SEND_CONNECTION_ID_SUCCESS = "SEND_CONNECTION_ID_SUCCESS";
export const SEND_CONNECTION_ID_FAILURE = "SEND_CONNECTION_ID_FAILURE";

// Bank account

export const ADD_BANK_ACCOUNT_START = "ADD_BANK_ACCOUNT_START";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_FAILURE = "ADD_BANK_ACCOUNT_FAILURE";

export const GET_BANK_ACCOUNT_START = "GET_BANK_ACCOUNT_START";
export const GET_BANK_ACCOUNT_SUCCESS = "GET_BANK_ACCOUNT_SUCCESS";
export const GET_BANK_ACCOUNT_FAILURE = "GET_BANK_ACCOUNT_FAILURE";

export const DELETE_BANK_ACCOUNT_START = "DELETE_BANK_ACCOUNT_START";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";
export const DELETE_BANK_ACCOUNT_FAILURE = "DELETE_BANK_ACCOUNT_FAILURE";

export const ADD_BANK_ACCOUNT_DATA = "ADD_BANK_ACCOUNT_DATA";

export const FETCH_SINGLE_BANK_ACCOUNT_START =
  "FETCH_SINGLE_BANK_ACCOUNT_START";
export const FETCH_SINGLE_BANK_ACCOUNT_SUCCESS =
  "FETCH_SINGLE_BANK_ACCOUNT_SUCCESS";
export const FETCH_SINGLE_BANK_ACCOUNT_FAILURE =
  "FETCH_SINGLE_BANK_ACCOUNT_FAILURE";

export const MAKE_DEFAULT_BANK_ACCOUNT_START =
  "MAKE_DEFAULT_BANK_ACCOUNT_START";
export const MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS =
  "MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS";
export const MAKE_DEFAULT_BANK_ACCOUNT_FAILURE =
  "MAKE_DEFAULT_BANK_ACCOUNT_FAILURE";

export const GET_KYC_DOCUMENT_START = "GET_KYC_DOCUMENT_START";
export const GET_KYC_DOCUMENT_SUCCESS = "GET_KYC_DOCUMENT_SUCCESS";
export const GET_KYC_DOCUMENT_FAILURE = "GET_KYC_DOCUMENT_FAILURE";

export const ADD_KYC_DOCUMENT_START = "ADD_KYC_DOCUMENT_START";
export const ADD_KYC_DOCUMENT_SUCCESS = "ADD_KYC_DOCUMENT_SUCCESS";
export const ADD_KYC_DOCUMENT_FAILURE = "ADD_KYC_DOCUMENT_FAILURE";

// Wallet constants

export const FETCH_WALLET_DETAILS_START = "FETCH_WALLET_DETAILS_START";
export const FETCH_WALLET_DETAILS_SUCCESS = "FETCH_WALLET_DETAILS_SUCCESS";
export const FETCH_WALLET_DETAILS_FAILURE = "FETCH_WALLET_DETAILS_FAILURE";

export const ADD_MONEY_VIA_CARD_START = "ADD_MONEY_VIA_CARD_START";
export const ADD_MONEY_VIA_CARD_SUCCESS = "ADD_MONEY_VIA_CARD_SUCCESS";
export const ADD_MONEY_VIA_CARD_FAILURE = "ADD_MONEY_VIA_CARD_FAILURE";

export const ADD_MONEY_VIA_BANK_START = "ADD_MONEY_VIA_BANK_START";
export const ADD_MONEY_VIA_BANK_SUCCESS = "ADD_MONEY_VIA_BANK_SUCCESS";
export const ADD_MONEY_VIA_BANK_FAILURE = "ADD_MONEY_VIA_BANK_FAILURE";

// Transaction constants.

export const FETCH_ALL_TRANSACTION_START = "FETCH_ALL_TRANSACTION_START";
export const FETCH_ALL_TRANSACTION_SUCCESS = "FETCH_ALL_TRANSACTION_SUCCESS";
export const FETCH_ALL_TRANSACTION_FAILURE = "FETCH_ALL_TRANSACTION_FAILURE";

export const FETCH_SENT_PAYMENT_TRANSACTION_START =
  "FETCH_SENT_PAYMENT_TRANSACTION_START";
export const FETCH_SENT_PAYMENT_TRANSACTION_SUCCESS =
  "FETCH_SENT_PAYMENT_TRANSACTION_SUCCESS";
export const FETCH_SENT_PAYMENT_TRANSACTION_FAILURE =
  "FETCH_SENT_PAYMENT_TRANSACTION_FAILURE";

export const FETCH_RECEIVED_PAYMENT_TRANSACTION_START =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_START";
export const FETCH_RECEIVED_PAYMENT_TRANSACTION_SUCCESS =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_SUCCESS";
export const FETCH_RECEIVED_PAYMENT_TRANSACTION_FAILURE =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_FAILURE";

export const FETCH_DEPOSIT_TRANSACTION_START =
  "FETCH_DEPOSIT_TRANSACTION_START";
export const FETCH_DEPOSIT_TRANSACTION_SUCCESS =
  "FETCH_DEPOSIT_TRANSACTION_SUCCESS";
export const FETCH_DEPOSIT_TRANSACTION_FAILURE =
  "FETCH_DEPOSIT_TRANSACTION_FAILURE";

// Invoice constants.

export const SAVE_BUSINESS_DETAILS_START = "SAVE_BUSINESS_DETAILS_START";
export const SAVE_BUSINESS_DETAILS_SUCCESS = "SAVE_BUSINESS_DETAILS_SUCCESS";
export const SAVE_BUSINESS_DETAILS_FAILURE = "SAVE_BUSINESS_DETAILS_FAILURE";

export const FETCH_BUSINESS_DETAILS_START = "FETCH_BUSINESS_DETAILS_START";
export const FETCH_BUSINESS_DETAILS_SUCCESS = "FETCH_BUSINESS_DETAILS_SUCCESS";
export const FETCH_BUSINESS_DETAILS_FAILURE = "FETCH_BUSINESS_DETAILS_FAILURE";

// Create Invoice constants.

export const SAVE_INVOICE_ID = "SAVE_INVOICE_ID";

export const SAVE_INVOICE_DETAILS_START = "SAVE_INVOICE_DETAILS_START";
export const SAVE_INVOICE_DETAILS_SUCCESS = "SAVE_INVOICE_DETAILS_SUCCESS";
export const SAVE_INVOICE_DETAILS_FAILURE = "SAVE_INVOICE_DETAILS_FAILURE";

export const SAVE_INVOICE_ITEMS_START = "SAVE_INVOICE_ITEMS_START";
export const SAVE_INVOICE_ITEMS_SUCCESS = "SAVE_INVOICE_ITEMS_SUCCESS";
export const SAVE_INVOICE_ITEMS_FAILURE = "SAVE_INVOICE_ITEMS_FAILURE";

export const FETCH_INVOICE_ITEMS_START = "FETCH_INVOICE_ITEMS_START";
export const FETCH_INVOICE_ITEMS_SUCCESS = "FETCH_INVOICE_ITEMS_SUCCESS";
export const FETCH_INVOICE_ITEMS_FAILURE = "FETCH_INVOICE_ITEMS_FAILURE";

export const DELETE_INVOICE_ITEMS_START = "DELETE_INVOICE_ITEMS_START";
export const DELETE_INVOICE_ITEMS_SUCCESS = "DELETE_INVOICE_ITEMS_SUCCESS";
export const DELETE_INVOICE_ITEMS_FAILURE = "DELETE_INVOICE_ITEMS_FAILURE";

export const UPDATE_INVOICE_ITEM = "UPDATE_INVOICE_ITEM";

export const FETCH_SINGLE_INVOICE_DETAILS_START =
  "FETCH_SINGLE_INVOICE_DETAILS_START";
export const FETCH_SINGLE_INVOICE_DETAILS_SUCCESS =
  "FETCH_SINGLE_INVOICE_DETAILS_SUCCESS";
export const FETCH_SINGLE_INVOICE_DETAILS_FAILURE =
  "FETCH_SINGLE_INVOICE_DETAILS_FAILURE";

export const SEND_INVOICE_START = "SEND_INVOICE_START";
export const SEND_INVOICE_SUCCESS = "SEND_INVOICE_SUCCESS";
export const SEND_INVOICE_FAILURE = "SEND_INVOICE_FAILURE";

// Manage Invoice constants.

export const FETCH_INVOICE_HISTORY_START = "FETCH_INVOICE_HISTORY_START";
export const FETCH_INVOICE_HISTORY_SUCCESS = "FETCH_INVOICE_HISTORY_SUCCESS";
export const FETCH_INVOICE_HISTORY_FAILURE = "FETCH_INVOICE_HISTORY_FAILURE";

export const FETCH_INVOICE_DRAFT_START = "FETCH_INVOICE_DRAFT_START";
export const FETCH_INVOICE_DRAFT_SUCCESS = "FETCH_INVOICE_DRAFT_SUCCESS";
export const FETCH_INVOICE_DRAFT_FAILURE = "FETCH_INVOICE_DRAFT_FAILURE";

export const FETCH_INVOICE_UNPAID_START = "FETCH_INVOICE_UNPAID_START";
export const FETCH_INVOICE_UNPAID_SUCCESS = "FETCH_INVOICE_UNPAID_SUCCESS";
export const FETCH_INVOICE_UNPAID_FAILURE = "FETCH_INVOICE_UNPAID_FAILURE";

export const FETCH_INVOICE_PAID_START = "FETCH_INVOICE_PAID_START";
export const FETCH_INVOICE_PAID_SUCCESS = "FETCH_INVOICE_PAID_SUCCESS";
export const FETCH_INVOICE_PAID_FAILURE = "FETCH_INVOICE_PAID_FAILURE";

export const FETCH_INVOICE_RECEIVED_START = "FETCH_INVOICE_RECEIVED_START";
export const FETCH_INVOICE_RECEIVED_SUCCESS = "FETCH_INVOICE_RECEIVED_SUCCESS";
export const FETCH_INVOICE_RECEIVED_FAILURE = "FETCH_INVOICE_RECEIVED_FAILURE";

// Withdraw constants

export const SEND_WITHDRAW_REQUEST_START = "SEND_WITHDRAW_REQUEST_START";
export const SEND_WITHDRAW_REQUEST_SUCCESS = "SEND_WITHDRAW_REQUEST_SUCCESS";
export const SEND_WITHDRAW_REQUEST_FAILURE = "SEND_WITHDRAW_REQUEST_FAILURE";

export const FETCH_WITHDRAWALS_START = "FETCH_WITHDRAWALS_START";
export const FETCH_WITHDRAWALS_SUCCESS = "FETCH_WITHDRAWALS_SUCCESS";
export const FETCH_WITHDRAWALS_FAILURE = "FETCH_WITHDRAWALS_FAILURE";

export const CANCEL_WITHDRAW_REQUEST_START = "CANCEL_WITHDRAW_REQUEST_START";
export const CANCEL_WITHDRAW_REQUEST_SUCCESS =
  "CANCEL_WITHDRAW_REQUEST_SUCCESS";
export const CANCEL_WITHDRAW_REQUEST_FAILURE =
  "CANCEL_WITHDRAW_REQUEST_FAILURE";

export const SEARCH_WITHDRAWALS_START = "SEARCH_WITHDRAWALS_START";
export const SEARCH_WITHDRAWALS_SUCCESS = "SEARCH_WITHDRAWALS_SUCCESS";
export const SEARCH_WITHDRAWALS_FAILURE = "SEARCH_WITHDRAWALS_FAILURE";

export const FETCH_SINGLE_WITHDRAWALS_START = "FETCH_SINGLE_WITHDRAWALS_START";
export const FETCH_SINGLE_WITHDRAWALS_SUCCESS =
  "FETCH_SINGLE_WITHDRAWALS_SUCCESS";
export const FETCH_SINGLE_WITHDRAWALS_FAILURE =
  "FETCH_SINGLE_WITHDRAWALS_FAILURE";

// Send money

export const SEARCH_USER_START = "SEARCH_USER_START";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILURE = "SEARCH_USER_FAILURE";

export const SEND_MONEY_START = "SEND_MONEY_START";
export const SEND_MONEY_SUCCESS = "SEND_MONEY_SUCCESS";
export const SEND_MONEY_FAILURE = "SEND_MONEY_FAILURE";

// Dispute constants

export const SEND_DISPUTE_REQUEST_START = "SEND_DISPUTE_REQUEST_START";
export const SEND_DISPUTE_REQUEST_SUCCESS = "SEND_DISPUTE_REQUEST_SUCCESS";
export const SEND_DISPUTE_REQUEST_FAILURE = "SEND_DISPUTE_REQUEST_FAILURE";

export const FETCH_DISPUTES_START = "FETCH_DISPUTES_START";
export const FETCH_DISPUTES_SUCCESS = "FETCH_DISPUTES_SUCCESS";
export const FETCH_DISPUTES_FAILURE = "FETCH_DISPUTES_FAILURE";

export const FETCH_OPENED_CASE_START = "FETCH_OPENED_CASE_START";
export const FETCH_OPENED_CASE_SUCCESS = "FETCH_OPENED_CASE_SUCCESS";
export const FETCH_OPENED_CASE_FAILURE = "FETCH_OPENED_CASE_FAILURE";

export const FETCH_CLOSED_CASE_START = "FETCH_CLOSED_CASE_START";
export const FETCH_CLOSED_CASE_SUCCESS = "FETCH_CLOSED_CASE_SUCCESS";
export const FETCH_CLOSED_CASE_FAILURE = "FETCH_CLOSED_CASE_FAILURE";

export const GET_SINGLE_DISPUTE_MESSAGES_START =
  "GET_SINGLE_DISPUTE_MESSAGES_START";
export const GET_SINGLE_DISPUTE_MESSAGES_SUCCESS =
  "GET_SINGLE_DISPUTE_MESSAGES_SUCCESS";
export const GET_SINGLE_DISPUTE_MESSAGES_FAILURE =
  "GET_SINGLE_DISPUTE_MESSAGES_FAILURE";

export const SEND_DISPUTE_MESSAGE_START = "SEND_DISPUTE_MESSAGE_START";
export const SEND_DISPUTE_MESSAGE_SUCCESS = "SEND_DISPUTE_MESSAGE_SUCCESS";
export const SEND_DISPUTE_MESSAGE_FAILURE = "SEND_DISPUTE_MESSAGE_FAILURE";

export const CANCEL_DISPUTE_REQUEST_START = "CANCEL_DISPUTE_REQUEST_START";
export const CANCEL_DISPUTE_REQUEST_SUCCESS = "CANCEL_DISPUTE_REQUEST_SUCCESS";
export const CANCEL_DISPUTE_REQUEST_FAILURE = "CANCEL_DISPUTE_REQUEST_FAILURE";

export const FETCH_SINGLE_DISPUTE_START = "FETCH_SINGLE_DISPUTE_START";
export const FETCH_SINGLE_DISPUTE_SUCCESS = "FETCH_SINGLE_DISPUTE_SUCCESS";
export const FETCH_SINGLE_DISPUTE_FAILURE = "FETCH_SINGLE_DISPUTE_FAILURE";

// Registeration verify.

export const REGISTER_VERIFY_START = "REGISTER_VERIFY_START";
export const REGISTER_VERIFY_SUCCESS = "REGISTER_VERIFY_SUCCESS";
export const REGISTER_VERIFY_FAILURE = "REGISTER_VERIFY_FAILURE";

export const REGISTER_VERIFY_RESEND_START = "REGISTER_VERIFY_RESEND_START";
export const REGISTER_VERIFY_RESEND_SUCCESS = "REGISTER_VERIFY_RESEND_SUCCESS";
export const REGISTER_VERIFY_RESEND_FAILURE = "REGISTER_VERIFY_RESEND_FAILURE";

// Pages

export const FETCH_SINGLE_PAGE_START = "FETCH_SINGLE_PAGE_START";
export const FETCH_SINGLE_PAGE_SUCCESS = "FETCH_SINGLE_PAGE_SUCCESS";
export const FETCH_SINGLE_PAGE_FAILURE = "FETCH_SINGLE_PAGE_FAILURE";

// Notification on or off

export const NOTIFICATION_STATUS_UPDATE_START =
  "NOTIFICATION_STATUS_UPDATE_START";
export const NOTIFICATION_STATUS_UPDATE_SUCCESS =
  "NOTIFICATION_STATUS_UPDATE_SUCCESS";
export const NOTIFICATION_STATUS_UPDATE_FAILURE =
  "NOTIFICATION_STATUS_UPDATE_FAILURE";

// Error Handling for logout

export const ERROR_LOGOUT_CHECK = "ERROR_LOGOUT_CHECK";

// Invoice payment

export const INVOICE_PAYNOW_START = "INVOICE_PAYNOW_START";
export const INVOICE_PAYNOW_SUCCESS = "INVOICE_PAYNOW_SUCCESS";
export const INVOICE_PAYNOW_FAILURE = "INVOICE_PAYNOW_FAILURE";
