import React, { Component } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import DashboardIndex from "../Dashboard/DashboardIndex";
import MainLayout from "../layouts/MainLayout";
import ResolutionCenterIndex from "../ResolutionCenter/ResolutionCenterIndex";
import WalletIndex from "../Wallet/WalletIndex";
import TransactionIndex from "../Wallet/Transaction/TransactionIndex";
import ProfileIndex from "../Accounts/Profile/ProfileIndex";
import EditProfile from "../Accounts/Profile/EditProfile";
import AuthLayout from "../layouts/AuthLayout";
import HomeIndex from "../Home/HomeIndex";
import SingleCaseDetailsIndex from "../ResolutionCenter/SingleCaseDetails.jsx/SingleCaseDetailsIndex";
import CardsList from "../Accounts/Cards/CardsList";
import ChangePasswordIndex from "../Accounts/ChangePassword/ChangePasswordIndex";
import DeleteAccountIndex from "../Accounts/DeleteAccount/DeleteAccountIndex";
import KYCDetails from "../Accounts/KYC/KYCDetails";
import Logout from "../Auth/Logout";
import AddMoneyIndex from "../Wallet/AddMoney/AddMoneyIndex";
import CreateInvoiceIndex from "../Invoice/CreateInvoice/CreateInvoiceIndex";
import ManageInvoiceIndex from "../Invoice/ManageInvoice/ManageInvoiceIndex";
import PreviewInvoiceIndex from "../Invoice/PreviewInvoice/PreviewInvoiceIndex";
import SendMoneyIndex from "../Wallet/SendMoney/SendMoneyIndex";
import NoResultFound from "../NoResultFound/NoResultFound";
import ErrorPage from "../ErrorPage/ErrorPage";
import PaymentSuccess from "../PaymentSuccess/PaymentSuccess";
import PaymentFailure from "../PaymentFailure/PaymentFailure";
import SubscriptionInvoiceNew from "../SubscriptionInvoiceNew/SubscriptionInvoiceNew";
import Welcome from "../Welcome/Welcome";
import EditInvoiceIndex from "../Invoice/EditInvoice/EditInvoiceIndex";
import DashboardLoader from "../Loader/DashboardLoader";
import MainLoader from "../Loader/MainLoader";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import Settings from "../Settings/Settings";
import StaticIndex from "../StaticPage/StaticIndex";
import RegisterVerifyIndex from "../Auth/RegisterVerifyIndex";
import NotFoundIndex from "../NotFoound/NotFoundIndex";
import BillingAccount from "../Accounts/BillingAccount/BillingAccount";
import BillingAccountList from "../Accounts/BillingAccount/BillingAccountList";
import AddBillingAccount from "../Accounts/BillingAccount/AddBillingAccount";
import EditBillingAccount from "../Accounts/BillingAccount/EditBillingAccount";
import KYCView from "../Accounts/KYC/KYCView";

const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    this.state = {
      loading: true,
      configLoading: true,
      authentication: userId && accessToken ? true : false,
    };

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");

      accessToken = localStorage.getItem("accessToken");

      this.setState({
        loading: true,
        authentication: userId && accessToken ? true : false,
      });

      document.body.scrollTop = 0;
    });
    this.fetchConfig();
  }

  async fetchConfig() {
    const response = await fetch(apiConstants.settingsUrl);

    const configValue = await response.json();

    configuration.set({ configData: configValue.data }, { freeze: false });
    this.setState({ configLoading: false });

    $("#google_analytics").html(
      configuration.get("configData.google_analytics")
    );

    $("#header_scripts").html(configuration.get("configData.header_scripts"));

    $("#body_scripts").html(configuration.get("configData.body_scripts"));
  }

  render() {
    const isLoading = this.state.configLoading;

    if (isLoading) {
      return (
        // Place content loadder here
        <div>
          <MainLoader></MainLoader>
        </div>
      );
    }

    return (
      <>
        <Helmet>
          <title>{configuration.get("configData.site_name")}</title>
          <link
            rel="icon"
            type="image/png"
            href={configuration.get("configData.site_icon")}
            sizes="16x16"
          />
          <meta
            name="description"
            content={configuration.get("configData.meta_description")}
          ></meta>
          <meta
            name="keywords"
            content={configuration.get("configData.meta_keywords")}
          ></meta>
          <meta
            name="author"
            content={configuration.get("configData.meta_author")}
          ></meta>
        </Helmet>

        <Switch>
          <AppRoute
            path={"/"}
            component={HomeIndex}
            exact
            layout={AuthLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/dashboard"}
            component={DashboardIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/manage-invoice"}
            component={ManageInvoiceIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/create-invoice"}
            component={CreateInvoiceIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/resolution-center"}
            component={ResolutionCenterIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/dispute-case/:id"}
            component={SingleCaseDetailsIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-invoice/:id"}
            component={EditInvoiceIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/preview-invoice/:id"}
            component={PreviewInvoiceIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/wallet"}
            component={WalletIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/add-money"}
            component={AddMoneyIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/transaction"}
            component={TransactionIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/send-money"}
            component={SendMoneyIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile"}
            component={ProfileIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-profile"}
            component={EditProfile}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/change-password"}
            component={ChangePasswordIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/delete-account"}
            component={DeleteAccountIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/verify-kyc"}
            component={KYCDetails}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/view-kyc"}
            component={KYCView}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/logout"}
            component={Logout}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/cards"}
            component={CardsList}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/no-result-found"}
            component={NoResultFound}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/error-page"}
            component={ErrorPage}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/payment-success"}
            component={PaymentSuccess}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/payment-failure"}
            component={PaymentFailure}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/subscription-invoice-new"}
            component={SubscriptionInvoiceNew}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/welcome"}
            component={Welcome}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/settings"}
            component={Settings}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/settings"}
            component={Settings}
            layout={MainLayout}
          />
          <AppRoute
            path={"/page/:title"}
            component={StaticIndex}
            layout={AuthLayout}
          />
          <AppRoute
            path={"/register/verify"}
            component={RegisterVerifyIndex}
            layout={AuthLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/add-billing-account"}
            component={AddBillingAccount}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/billing-account"}
            component={BillingAccount}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/billing-account-list"}
            component={BillingAccountList}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-billing-account/:id"}
            component={EditBillingAccount}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/single-billing-account/:id"}
            component={BillingAccount}
            layout={MainLayout}
          />
          <Route path="*" component={NotFoundIndex} />
        </Switch>
      </>
    );
  }
}

export default App;
